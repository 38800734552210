import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import { onMessage } from 'firebase/messaging';
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";

const FirebaseData = () => {

  const setting = useSelector((state) => state.setting);


  if (setting.setting === null) {
    return <Loader screen='full' />;
  }

  // const apiKey = setting.setting && setting.setting.firebase.apiKey;
  // const authDomain = setting.setting && setting.setting.firebase.authDomain;
  // const projectId = setting.setting && setting.setting.firebase.projectId;
  // const storageBucket = setting.setting && setting.setting.firebase.storageBucket;
  // const messagingSenderId = setting.setting && setting.setting.firebase.messagingSenderId;
  // const appId = setting.setting && setting.setting.firebase.appId;
  // const measurementId = setting.setting && setting.setting.firebase.measurementId;


  // const firebaseConfig = {
  //   apiKey: apiKey,
  //   authDomain: authDomain,
  //   projectId: projectId,
  //   storageBucket: storageBucket,
  //   messagingSenderId: messagingSenderId,
  //   appId: appId,
  //   measurementId: measurementId,
  // };

  const firebaseConfig = {
    apiKey: "AIzaSyDlChGK6I_bF33PwD84OQqq7sJxr3lrvQM",
    authDomain: "sebmultiventures-a8bed.firebaseapp.com",
    projectId: "sebmultiventures-a8bed",
    storageBucket: "sebmultiventures-a8bed.appspot.com",
    messagingSenderId: "980565221691",
    appId: "1:980565221691:web:f9c8a5fed8372ff84b971e",
    measurementId: "G-1667518MQ3"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

  const auth = firebase.auth();

  const messaging = firebase.messaging();

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // ...
  });

  return { auth, firebase, messaging };
};

export default FirebaseData;